import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import BtnArrow from "../assets/images/button_arrow-top.svg"
import BtnArrowbottom from "../assets/images/button_arrowbottom.svg"
import "swiper/css";
import "swiper/css/pagination";

import SwiperCore, { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper';
import CapabilitesMLSlider from "./CapabilitesMobileSlider";

// Install Swiper modules
SwiperCore.use([Autoplay, Navigation, Pagination, Scrollbar]);


function CapabilitesSolutions(props) {

  const [isActiveEven, setIsActiveEven] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsActiveEven(prevState => !prevState);
    }, 1000); // Toggle active class between even and odd list items every second

    return () => clearInterval(interval);
  }, []);

  return (
    <section className="service-section capabilities-consulting capabilities-solution">
      <div className=" container pt-5">
        <div className="row">
          <div className=" col-md-6 text-white mb-5 mb-lg-0 capabilities_block_content ">
            <span className="badge bg-muted-purple text-white rounded-pill mb-2">
              Process Automation Services
            </span>
            <h1>
              Tailored <span className="text-green">Hardware</span>
            </h1>
            <p>Our Automation Services leverage the latest technology to optimize routine and complex operations. Our Integrations team ensures collaborative efforts, working with your existing internal and external workforce to guarantee project success.</p>
            <a href="/contact/" className='button-link'>Contact Us
              <div className="arrow_movement">
                <img src={BtnArrow} alt='' />
                <img src={BtnArrowbottom} alt='' />
              </div>
            </a>
          </div>
          <div className=" col-md-6 varticle-scroll-right">
            <div className="cap-solution-box">
              <div className={`cap-box ${isActiveEven ? 'active' : ''}`}>
                <div className="verticle-box">Automations</div>
                <div className="verticle-box">Kiosks</div>
              </div>
              <div className={`cap-box ${!isActiveEven ? 'active' : ''}`}>
                <div className="verticle-box">Signage</div>
                <div className="verticle-box">IoT Devices</div>
              </div>
              <div className={`cap-box ${isActiveEven ? 'active' : ''}`}>
                <div className="verticle-box">POS System</div>
                <div className="verticle-box">Robots</div>
              </div>
              <div className={`cap-box ${!isActiveEven ? 'active' : ''}`}>
                <div className="verticle-box">Robots</div>
                <div className="verticle-box">POS System</div>
              </div>
              <div className={`cap-box ${isActiveEven ? 'active' : ''}`}>
                <div className="verticle-box">IoT Devices</div>
                <div className="verticle-box">Signage</div>
              </div>
              <div className={`cap-box ${!isActiveEven ? 'active' : ''}`}>
                <div className="verticle-box">Kiosks</div>
                <div className="verticle-box">Automations</div>
              </div>
            </div>

          </div>

        </div>

      </div>
      <CapabilitesMLSlider />
    </section>
  )
}


export default CapabilitesSolutions;