import React, { useRef, useState } from "react";
import ImageAniamtion from "../assets/images/checkmark.svg"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import SwiperCore, { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper';

// Install Swiper modules
SwiperCore.use([Autoplay, Navigation, Pagination, Scrollbar]);


function CapabilitesMLSlider(props) {

    return (
        <div className='mobile-slider capability-solution-mobile-slider advance-solution-mobile-slider'>
            
             <div className="top-slider">
               
             <Swiper
            spaceBetween={32}
            centeredSlides= {true}
            speed={5000}
            loop={true}
            autoplay={{
              delay: 1,
             
          }}  
          autoHeight = {true}
          slidesPerView = {"auto"}
          allowTouchMove ={false}
          disableOnInteraction = { true}
          className='capabilities-loop-slider'
        >   <SwiperSlide>
            <div className='ai-box'>
                 <img src={ImageAniamtion} alt='' /> Automations
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Signage
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
                 <img src={ImageAniamtion} alt='' /> POS System
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Robots
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
                 <img src={ImageAniamtion} alt='' /> IoT Devices
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Kiosks
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
                 <img src={ImageAniamtion} alt='' /> Automations
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Signage
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
                 <img src={ImageAniamtion} alt='' /> POS System
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Robots
            </div>
            </SwiperSlide>
            </Swiper>
            </div>
         <div className="bottom_slider">
         <Swiper
            spaceBetween={32}
            centeredSlides= {true}
            speed={5000}
            loop={true}
            autoplay={{
              delay: 1,
              reverseDirection: true,
          }}  
          autoHeight = {true}
          slidesPerView = {"auto"}
          allowTouchMove ={false}
          disableOnInteraction = { true}
          className='capabilities-loop-slider'
        >   <SwiperSlide>
            <div className='ai-box'>
                 <img src={ImageAniamtion} alt='' /> Automations
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Signage
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
                 <img src={ImageAniamtion} alt='' /> Robots
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> POS System
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
                 <img src={ImageAniamtion} alt='' /> Automations
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Kiosks
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
                 <img src={ImageAniamtion} alt='' /> IoT Devices
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Robots
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
                 <img src={ImageAniamtion} alt='' /> POS System
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className='ai-box'>
            <img src={ImageAniamtion} alt='' /> Signage
            </div>
            </SwiperSlide>
            </Swiper>
            </div>
        </div>

    )
}


export default CapabilitesMLSlider;




